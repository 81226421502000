import { NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
    standalone: true,
    selector: 'form-column',
    imports: [NgClass],
    template: `<div class="d-inline-flex flex-column w-100" [ngClass]="marginClass"><ng-content></ng-content></div>`
})
export class FormColumnComponent {

    @Input() marginClass: string = 'my-2';
}